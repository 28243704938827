import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    active_mode: null,
    customer: null,
    orders: [],
    orders_filters: { limit: 20, offset: 0, ordering: '-order_id' },
    orders_count: 0,
    orders_loading: false,
    orders_loading_errors: null,
    orders_cancel_source: null,

    orders_stats: {},
    orders_stats_loaded: false,
    orders_stats_loading: false,
    orders_stats_loading_errors: null,
};

let getModeFilters = function (active_mode, customer) {
    const filters = {
        exclude_readonly: false,
        customer: customer,
    };
    filters.suborder = false;

    switch (active_mode) {
        case 'waiting':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            filters.status = 'waiting';
            break;
        case 'handling':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            filters.status__neq = 'waiting';
            break;
        case 'drafts':
            filters.ready = false;
            filters.readonly = false;
            filters.exclude_readonly = false;
            break;
        case 'ready':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            break;
        case 'pending':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            filters.status = 'waiting';
            break;
        case 'processing':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            filters.status__neq = 'waiting';
            break;
        case 'waitingpayment':
            filters.ready = true;
            filters.readonly = true;
            filters.invoices_sent = true;
            filters.paid = false;
            filters.status = "completed";
            break;
        case 'providerorders':
            filters.providerorder = true;
            filters.suborder = true;
            filters.customer = null;
            break;
        case 'invoicepending':
            filters.ready = true;
            filters.readonly = true;
            filters.invoices_ready = false;
            filters.paid = false;
            filters.status = "completed";
            break;
        case 'invoicenotsent':
            filters.ready = true;
            filters.readonly = true;
            filters.invoices_ready = true;
            filters.invoices_sent = false;
            filters.paid = false;
            filters.status = "completed";
            break;
        case 'tohandle':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            filters.status = 'waiting';
            break;
    }
    return filters;

};

const mutations = {
    updateOrdersStats(state, orders_stats) {
        state.orders_stats = orders_stats;
        state.orders_stats_loaded = true;
    },
    updateOrdersStatsLoading(state, loading) {
        state.orders_stats_loading = loading;
    },
    updateOrdersStatsLoadingErrors(state, errors) {
        state.orders_stats_loading_errors = errors;
    },
    updateMode(state, mode) {
        state.orders = [];
        state.orders_count = 0;
        state.active_mode = mode;

        state.orders_filters = {
            offset: 0,
            limit: state.orders_filters.limit || 20,
            search: state.orders_filters.search,
            ordering: state.orders_filters.ordering,
            customer: state.orders_filters.customer,
        };
        if (state.customer) {
            state.orders_filters.customer = state.customer;
        }

        state.orders_filters = Object.assign({}, state.orders_filters, getModeFilters(state.active_mode, state.customer));
    },


    updateOrders(state, orders) {
        state.orders = orders;
    },
    updateOrdersCount(state, count) {
        state.orders_count = count;
    },
    updateOrdersLoading(state, loading) {
        state.orders_loading = loading;
    },
    updateOrdersLoadingErrors(state, errors) {
        state.orders_loading_errors = errors;
    },
    updateOrdersFilters(state, orders_filters) {
        state.orders_filters = Object.assign(
            {
                limit: state.orders_filters.limit || 20,
                ordering: state.orders_filters.ordering,
            },
            getModeFilters(state.active_mode, state.customer),
            orders_filters);
    },

    updateOrdersCancelSource(state, source) {
        state.orders_cancel_source = source;
    },

    updateCustomer(state, customer) {
        state.customer = customer;
    },

};

const actions = {
    changeMode({ commit, dispatch, state }, mode) {
        if (mode == state.active_mode) {
            return;
        }
        commit('updateMode', mode);
        dispatch('fetchOrders');
        dispatch('session/fetchStats', null, { root: true });
    },

    exportOrdersAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.orders_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/customerorders/?" + queryparams);
    },

    exportOrderItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            for (let key in state.orders_filters) {
                filters['order_' + key] = state.orders_filters[key];
            }

            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/customerorderitems/?" + queryparams);
    },

    async fetchOrders({ commit, dispatch, state }, params) {
        commit('updateOrdersLoading', true);
        commit('updateOrdersLoadingErrors', null);

        if (state.orders_cancel_source) {
            state.orders_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateOrdersCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/customerorders/', {
                params: state.orders_filters,
                cancelToken: state.orders_cancel_source?.token,
            });
            commit('updateOrdersLoading', false);
            commit('updateOrders', response.data.results);
            commit('updateOrdersCount', response.data.count);
            commit('updateOrdersCancelSource', null);
            dispatch('fetchOrdersStats');

            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateOrdersLoadingErrors', error.details);
            commit('updateOrdersLoading', false);
            commit('updateOrdersCancelSource', null);
            return Promise.reject(error);
        }
    },

    async assignOrder({ commit, dispatch, state }, params) {
        const url = `/api/customerorders/${params.order.id}/assign/`;

        try {
            const response = await axios.put(url, { user: params.user });
            dispatch('fetchOrders');
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            return Promise.reject(utils.handleError(xhr_error));
        }
    },

    async fetchOrdersStats({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.view_order") == -1) {
            return;
        }

        commit('updateOrdersStatsLoading', true);
        commit('updateOrdersStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/customerorders/stats/', { params: state.orders_filters });
            commit('updateOrdersStatsLoading', false);
            commit('updateOrdersStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrdersStatsLoadingErrors', error.details);
            commit('updateOrdersStatsLoading', false);
            throw error;
        }
    },

    async init({ commit, dispatch, state }, params) {
        commit('updateCustomer', params?.customer);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
